<template>
  <p>{{ buyer }}</p>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      buyer: {
        verified: true,
        address: "دمشق الحمرا، جانب مكتب القدموس للنقليات",
        id: "132124sdfae",
        active_cart_id: "24rqwq34t253frf",
        purchases: ["asdfqfwf4", "a4fqwf4qf4", "4fq34f4fqwfwf"],
        purchases_count: 3,
        name: "eyad alshami",
        total_purchases: 10000,
        last_purchase_date: moment().format("DD/MM/YYYY")
      }
    };
  },
  created() {
    console.log("buyer Info created");
  }
};
</script>

<style lang="scss" scoped></style>
