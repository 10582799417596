<template>
  <div class="m-commenter-section">
    <div class="taps-container">
      <div class="m-tab-nav rounded-corners">
        <router-link
          v-for="link in links"
          :to="{ name: link.href }"
          exact
          :key="link.name"
          tag="div"
          class="m-tab rounded-corners"
        >
          <span>{{ link.name }}</span>
          <font-awesome-icon :icon="link.icon" />
          <!-- <img :src="link.icon" /> -->
        </router-link>
      </div>
    </div>
    <router-view class="router-container"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          name: "المشترين",
          href: "chatbot.delivery.buyers.table",
          icon: "users"
        },
        // {
        //   name: "الطلبات السابقة",
        //   href: "chatbot.delivery.delivered",
        //   icon: "clipboard-check"
        // },
        {
          name: "الطلبات الحالية",
          href: "chatbot.delivery.pending",
          icon: "truck"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
* {
  font-family: cairo;
}

.m-commenter-section {
  display: flex;
  flex-direction: column;
  .taps-container {
    flex: 0 0 auto;
  }
  .router-container {
    flex: 1 1 auto;
  }
}

.right-aligned-section {
  display: flex;
  justify-content: flex-end;
}
</style>
