<template>
  <loadingSpinner v-if="loading" position="absolute"></loadingSpinner>
  <div v-else-if="buyers.length !== 0">
    <div class="m-items-table" v-if="buyers != null && buyers.length > 0">
      <div class="thead">
        <div class="tr">
          <div
            class="th"
            v-for="(property, index) in $store.getters['delivery/buyerViewProperties']"
            :class="getCssClass(property.cssClass)"
            :key="index"
          >
            <font-awesome-icon
              icon="sort-up"
              v-if="property.orderBy"
              class="sort-arrow"
              @click="setorderBy(property.orderByPropertyName)"
            />
            {{ property.title }}
          </div>
        </div>
      </div>

      <div class="tbody">
        <div class="tr" v-for="(buyer, buyerIndex) in buyers" :key="buyerIndex">
          <div
            class="td"
            v-for="(property, index) in $store.getters['delivery/buyerViewProperties']"
            :key="index"
            :data="property.title"
            :class="getCssClass(property.cssClass)"
          >
            <b-checkbox v-if="property.name === 'select'"></b-checkbox>
            <span v-if="property.name === 'name'">{{ buyer.buyer.name }}</span>
            <div v-if="property.name === 'picture'">
              <img :src="buyer.buyer.profile_pic" alt="" />
            </div>
            <span v-else-if="property.name === 'last_purchase_date'">
              {{ checkoutTime(buyer.last_purchase_date) }}
            </span>
            <span v-else-if="property.name === 'purchases_count'">{{ buyer.purchases_count }}</span>
            <span v-else-if="property.name === 'total_purchases'">{{ buyer.total_purchases }}</span>
            <span class="relative" v-else-if="property.name === 'options'">
              <span class="buyer-options" @click="showBuyerHistory(buyer.id)">
                <font-awesome-icon icon="history" />
              </span>
              <div class="absolute cartOptions" v-if="showOptions_CartIndex === buyerIndex">
                <p @click="confirmPurchase([buyer.id])">
                  موافقة<font-awesome-icon icon="check" size="xs" />
                </p>
                <p>رفض<font-awesome-icon icon="times" size="xs" /></p>
              </div>
            </span>
            <!-- <template v-else>
              <button class="m-edit" data="تعديل" @click="editProduct(buyer.id)">
                <font-awesome-icon icon="edit" />
              </button>
              <button class="m-delete" data="حذف" @click="confirmDeletion(buyer)">
                <font-awesome-icon icon="trash-alt" />
              </button>
            </template> -->
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-control">
      <button
        class="mujeeb-button"
        v-if="paginationStates[paginationState].next"
        @click="fetchPage"
      >
        التالي<font-awesome-icon class="left-margin" icon="chevron-right" />
      </button>
      <button
        class="mujeeb-button"
        v-if="paginationStates[paginationState].back"
        @click="fetchPage(false)"
      >
        <font-awesome-icon class="right-margin" icon="chevron-left" /> السابق
      </button>
    </div>
  </div>
  <div v-else-if="buyers.length === 0" class="empty-data">
    <p>لا يوجد مشترين</p>
  </div>
</template>

<script>
import moment from "moment";
import loadingSpinner from "./../../../loading/loading-spinner";
import PaginationMixin from "./../../../../mixins/pagination_2";
export default {
  mixins: [PaginationMixin(`/services/delivery/.../shoppers`, "buyers", "buyer.name", "desc")],
  components: {
    loadingSpinner
  },
  data() {
    return {
      buyers: [
        {
          verified: true,
          address: "دمشق الحمرا، جانب مكتب القدموس للنقليات",
          id: "132124sdfae",
          active_cart_id: "24rqwq34t253frf",
          purchases: ["asdfqfwf4", "a4fqwf4qf4", "4fq34f4fqwfwf"],
          purchases_count: 3,
          name: "eyad alshami",
          total_purchases: 10000,
          last_purchase_date: moment().valueOf()
        }
      ],
      showMoreProducts_CartIndex: null,
      showOptions_CartIndex: null
    };
  },
  methods: {
    getCssClass(cssClass) {
      const classesObject = {};
      classesObject[
        `item-width-${this.$store.getters["delivery/buyerViewProperties"].length}`
      ] = true;
      if (cssClass) {
        classesObject[cssClass] = true;
      }
      return classesObject;
    },
    checkoutTime(time) {
      let momentDate = moment(time);
      let dif = moment().diff(momentDate, "hours");
      if (dif >= 24 && dif <= 48) {
        return "البارحة ";
      } else if (dif < 24 && dif >= 0) {
        return "اليوم";
      } else {
        return momentDate.format("DD/MM/YYYY");
      }
    },
    showBuyerHistory(id) {
      // this.$router.push({ name: "chatbot.delivery.buyers.info", params: { id } });
    }
  },
  created() {
    console.log("buyers Table created");
  }
};
</script>

<style scoped lang="scss">
.m-items-table {
  direction: rtl;
}
.picture {
  div {
    width: 40px;
    height: 40px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.empty-data {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 1px dashed black;
  border-radius: 6px;
}
</style>
