/* eslint-disable camelcase */
import bluebird from "bluebird";

function PaginationMixin(url, arrayName, orderBy, sorting) {
  return {
    data() {
      const states = {
        created: {
          next: false,
          back: false
        },
        onePage: {
          next: false,
          back: false
        },
        firstPage: {
          next: true,
          back: false
        },
        lastPage: {
          next: false,
          back: true
        },
        CanFetch: {
          next: true,
          back: true
        }
      };
      const [URL_PREFIX, URL_POSTFIX] = url.split("...");
      const finalURL =
        URL_PREFIX +
        `users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}` +
        URL_POSTFIX;
      return {
        loading: false,
        paginationStates: states,
        paginationState: "created",
        direction: "next",
        url: finalURL,
        orderBy: orderBy,
        sorting: sorting // desc | asc
      };
    },
    methods: {
      fetchPage(next = true) {
        this.loading = true;
        const queryParams = { orderBy: this.orderBy, sorting: this.sorting };
        if (next) {
          this.direction = "next";
          queryParams.nextStartAfter = this.$store.getters["itemsTable/nextStartAfter"];
        } else {
          this.direction = "back";
          queryParams.backStartAfter = this.$store.getters["itemsTable/backStartAfter"];
        }
        return this.$api
          .customRequest({
            method: "get",
            url: this.url,
            responseType: "json",
            params: queryParams
          })
          .then(({ data }) => {
            console.log(data);
            this[arrayName] = data.objectsArray;
            const complexOrderBy = this.orderBy.split(".");
            if (this[arrayName] != null && this[arrayName].length !== 0) {
              console.log(complexOrderBy);
              this.$store.dispatch(
                "itemsTable/setNextStartAfter",
                complexOrderBy.length <= 1
                  ? this[arrayName][this[arrayName].length - 1][this.orderBy]
                  : this[arrayName][this[arrayName].length - 1][complexOrderBy[0]][
                      complexOrderBy[1]
                    ]
              );
              this.$store.dispatch(
                "itemsTable/setBackStartAfter",
                complexOrderBy.length <= 1
                  ? this[arrayName][0][this.orderBy]
                  : this[arrayName][0][complexOrderBy[0]][complexOrderBy[1]]
              );
              this.nextPaginationState(data.can_fetch, this[arrayName].length);
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      nextPaginationState(can_fetch, length) {
        switch (this.paginationState) {
          case "created":
            if (can_fetch) {
              this.paginationState = "firstPage";
            } else {
              this.paginationState = "onePage";
            }
            break;
          case "onePage":
            break;
          case "firstPage":
            if (can_fetch) {
              this.paginationState = "CanFetch";
            } else {
              this.paginationState = "lastPage";
            }
            break;
          case "lastPage":
            if (can_fetch) {
              this.paginationState = "CanFetch";
            } else {
              this.paginationState = "firstPage";
            }
            break;
          case "CanFetch":
            if (can_fetch) {
              this.paginationState = "CanFetch";
            } else {
              this.paginationState = this.direction === "next" ? "lastPage" : "firstPage";
            }
            break;
        }
      },
      setorderBy(orderBy) {
        if (orderBy !== this.orderBy) {
          this.orderBy = orderBy;
          this.sorting = "desc";
        } else {
          this.sorting = this.sorting === "desc" ? "asc" : "desc";
        }
        this.$store.dispatch("itemsTable/resetPointers").then(() => {
          this.paginationState = "created";
          this.fetchPage();
        });
      }
    },
    created() {
      this.loading = true;
      this.$store.dispatch("itemsTable/resetPointers");
      this.fetchPage();
    },
    beforeDestroy() {
      this.$store.dispatch("itemsTable/resetPointers");
    }
  };
}

export default PaginationMixin;
