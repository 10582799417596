<template>
  <router-view class="router-container"></router-view>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {};
  },
  created() {
    console.log("buyer created");
  },
  beforeDestroy() {
    console.log("buyer destroyed");
  }
};
</script>

<style scoped lang="scss">
.m-items-table {
  direction: rtl;
}
</style>
