<template>
  <loadingSpinner v-if="loading" position="absolute"></loadingSpinner>
  <div v-else-if="carts.length !== 0">
    <p v-if="Object.keys(selectedCarts) != 0">Selected carts Options</p>
    <div class="m-items-table" v-if="carts != null && carts.length > 0">
      <div class="thead">
        <div class="tr">
          <div
            class="th"
            v-for="(property, index) in $store.getters['delivery/cartViewProperties']"
            :class="getCssClass(property.cssClass)"
            :key="index"
          >
            <font-awesome-icon
              icon="sort-up"
              v-if="property.orderBy"
              class="sort-arrow"
              @click="setorderBy(property.name)"
            />
            {{ property.title }}
          </div>
        </div>
      </div>

      <div class="tbody">
        <div class="tr" v-for="(cart, cartIndex) in carts" :key="cartIndex">
          <div
            class="td"
            v-for="(property, index) in $store.getters['delivery/cartViewProperties']"
            :key="index"
            :data="property.title"
            :class="getCssClass(property.cssClass)"
          >
            <b-checkbox
              v-if="property.name === 'select'"
              @input="checked(cart.cartId, $event)"
            ></b-checkbox>

            <span v-if="property.name === 'buyer'">{{ cart.buyer.name }}</span>

            <span v-else-if="property.name === 'checkout_time'">
              {{ checkoutTime(cart.checkout_time) }}
            </span>

            <span v-else-if="property.name === 'products'">
              <span class="product-name">{{ Object.values(cart.products)[0].name }}</span>
              <span
                class="product-name more"
                v-if="Object.keys(cart.products).length > 1"
                @click="showMoreProducts(cartIndex)"
              >
                <font-awesome-icon icon="ellipsis-h" />
                <div class="more-produts" v-if="showMoreProducts_CartIndex === cartIndex">
                  <p v-for="id in Object.keys(cart.products).slice(1)" :key="id">
                    {{ cart.products[id].name }}
                  </p>
                </div>
              </span>
            </span>

            <span v-else-if="property.name === 'receipt'">{{ cart.receipt }}</span>

            <span
              class="relative"
              :class="{ 'options-trigger': showOptions_CartIndex === cartIndex }"
              v-else-if="property.name === 'options'"
            >
              <span class="cart-options-trigger" @click="showOptions(cartIndex)">
                <font-awesome-icon icon="ellipsis-h" />
              </span>
              <div class="absolute cartOptions" v-if="showOptions_CartIndex === cartIndex">
                <p @click="acceptOrder([cart.cartId])">
                  موافقة<font-awesome-icon icon="check" size="xs" />
                </p>
                <p @click="rejectOrder([cart.cartId])">
                  رفض<font-awesome-icon icon="times" size="xs" />
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-control">
      <button
        class="mujeeb-button"
        v-if="paginationStates[paginationState].next"
        @click="fetchPage"
      >
        التالي<font-awesome-icon class="left-margin" icon="chevron-right" />
      </button>
      <button
        class="mujeeb-button"
        v-if="paginationStates[paginationState].back"
        @click="fetchPage(false)"
      >
        <font-awesome-icon class="right-margin" icon="chevron-left" /> السابق
      </button>
    </div>
  </div>
  <div v-else-if="carts.length === 0" class="empty-data">
    <p>لايوجد طلبات جديدة</p>
  </div>
</template>

<script>
import moment from "moment";
import loadingSpinner from "./../../../loading/loading-spinner";
import PaginationMixin from "./../../../../mixins/pagination_2";
export default {
  components: {
    loadingSpinner
  },
  data() {
    return {
      selectedCarts: {},
      carts: [],
      showMoreProducts_CartIndex: null,
      showOptions_CartIndex: null,
      orderBy: "checkout_time",
      sorting: "desc" // asc
    };
  },
  mixins: [PaginationMixin(`/services/delivery/.../pending`, "carts", "checkout_time", "desc")],
  methods: {
    getCssClass(cssClass) {
      const classesObject = {};
      classesObject[
        `item-width-${this.$store.getters["delivery/cartViewProperties"].length}`
      ] = true;
      if (cssClass) {
        classesObject[cssClass] = true;
      }
      return classesObject;
    },
    checkoutTime(time) {
      let momentDate = moment(time);
      let dif = moment().diff(momentDate, "hours");
      if (dif >= 24 && dif <= 48) {
        return "البارحة ";
      } else if (dif < 24 && dif >= 0) {
        return "اليوم";
      } else {
        return momentDate.format("DD/MM/YYYY");
      }
    },
    showMoreProducts(index) {
      if (index != this.showMoreProducts_CartIndex) {
        this.showMoreProducts_CartIndex = index;
      } else {
        this.showMoreProducts_CartIndex = null;
      }
    },
    showOptions(index) {
      if (index != this.showOptions_CartIndex) {
        this.showOptions_CartIndex = index;
      } else {
        this.showOptions_CartIndex = null;
      }
    },
    //
    acceptOrder(ids) {
      console.log(ids);
      if (ids.length === 1) {
        this.$buefy.dialog.confirm({
          title: `تاكيد عملية الشراء`,
          message: `هل أنت متأكد من تأكيد عملية الشراء هذه؟`,
          confirmText: "متأكد",
          cancelText: "كلا",
          type: "is-success",
          onConfirm: () => {
            // TODO make a request to confirm this purchase
            // TODO remove or refetch the pending purchases
            this.showOptions_CartIndex = null;
            this.loading = true;
            this.apiStateRequest(ids)
              .then(() => {
                return this.$store.dispatch("itemsTable/resetPointers");
              })
              .then(() => {
                this.paginationState = "created";
                return this.fetchPage();
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          onCancel: () => {
            this.showOptions_CartIndex = null;
          }
        });
      } else {
        this.$buefy.dialog.confirm({
          title: `تاكيد عملية الشراء`,
          message: `هل أنت متأكد من تأكيد عمليات الشراء هذه؟`,
          confirmText: "متأكد",
          cancelText: "كلا",
          type: "is-success",
          onConfirm: () => {
            // TODO make a request to confirm this purchase
            // TODO remove or refetch the pending purchases
            this.apiStateRequest(ids);
            console.log("confirming the purchase");
            this.showOptions_CartIndex = null;
          }
        });
      }
    },
    rejectOrder(ids) {
      if (ids.length === 1) {
        this.$buefy.dialog.prompt({
          message: `ماهو سبب رفض عملية الشراء هذه؟`,
          confirmText: "تاكيد الرفض",
          cancelText: "إلغاء",
          inputAttrs: {
            placeholder: "مثلا: عدم توافر القطعة المرغوبة",
            maxlength: 200
          },
          trapFocus: true,
          onConfirm: feedback => {
            this.showOptions_CartIndex = null;
            this.loading = true;
            this.apiStateRequest(ids, true, feedback)
              .then(() => {
                return this.$store.dispatch("itemsTable/resetPointers");
              })
              .then(() => {
                this.paginationState = "created";
                return this.fetchPage();
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          onCancel: () => {
            this.showOptions_CartIndex = null;
          }
        });
      } else {
        this.$buefy.dialog.prompt({
          message: `ماهو سبب رفض عمليات الشراء هذه؟`,
          confirmText: "تاكيد الرفض",
          cancelText: "إلغاء",
          inputAttrs: {
            placeholder: "مثلا: عدم توافر القطعة المرغوبة",
            maxlength: 200
          },
          trapFocus: true,
          onConfirm: feedback => {
            this.showOptions_CartIndex = null;
            this.loading = true;
            this.apiStateRequest(ids, true, feedback)
              .then(() => {
                return this.$store.dispatch("itemsTable/resetPointers");
              })
              .then(() => {
                this.paginationState = "created";
                return this.fetchPage();
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          onCancel: () => {
            this.showOptions_CartIndex = null;
          }
        });
      }
    },
    apiStateRequest(ids, reject = false, feedback = "") {
      let request_options = {
        method: "put"
      };
      console.log("_____________________-");
      console.log(feedback);
      console.log("_____________________-");
      if (Array.isArray(ids) && ids.length > 1) {
        request_options.url = `/services/delivery/users/${this.$store.getters["user/id"]}/pages/${
          this.$store.getters["page/id"]
        }/pending/batch/${reject ? "reject" : "fulfil"}`;
        request_options.data = {
          cartsIds: ids,
          feedback
        };
      } else {
        request_options.url = `/services/delivery/users/${this.$store.getters["user/id"]}/pages/${
          this.$store.getters["page/id"]
        }/pending/${ids}/${reject ? "reject" : "fulfil"}`;
        if (reject) {
          request_options.data = {
            feedback
          };
        }
      }
      return this.$api.customRequest(request_options);
    },
    //
    isVisible(elem) {
      const res =
        !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      return res;
    },
    clickOutSideTootlTipTrigger(event) {
      // const repliesTooltip = document.getElementsByClassName("options-trigger")[0];
      const replyTrigger = document.querySelectorAll(".options-trigger")[0];
      if (
        // this.isVisible(repliesTooltip) &&
        // !repliesTooltip.contains(event.target) // &&
        this.isVisible(replyTrigger) &&
        !replyTrigger.contains(event.target)
      ) {
        // clicking outside the subnav menu
        this.showOptions_CartIndex = null;
      }
    },
    //
    checked(id, checked) {
      if (checked) {
        this.$set(this.selectedCarts, id, true);
      } else {
        if (this.selectedCarts[id]) {
          this.$delete(this.selectedCarts, id);
        }
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.clickOutSideTootlTipTrigger);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSideTootlTipTrigger);
  }
};
</script>

<style scoped lang="scss">
.m-items-table {
  direction: rtl;
  .sort-arrow {
    cursor: pointer;
  }
}
div.tbody > div > div.td.checkBox {
  margin: 0 !important;
  margin-left: 20px !important;
}

.cartOptions {
  background: white;
  border-radius: 6px;
  top: 120%;
  left: -10%;
  box-shadow: $shadow-md;
  z-index: 5;
  width: 100px;
  p {
    direction: rtl;
    text-align: right;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    &:hover {
      background: #f4f8f9;
    }
  }
}

.cart-options-trigger {
  border-radius: 50%;
  padding: 2px 8px;
  background: #f4f8f9;
}

.product-name {
  padding: 1px 5px;
  border-radius: 6px;
  background: #f4f8f9;
  color: black;
  &.more {
    border-radius: 50px;
    padding: 2px 8px;
    margin-right: 5px;
    position: relative;
    .more-produts {
      z-index: 5;
      position: absolute;
      top: 105%;
      right: 0px;
      border-radius: 6px;
      background: white;
      padding: 8px;
      box-shadow: $shadow-md;
      p {
        white-space: nowrap;
      }
    }
  }
}

.empty-data {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 1px dashed black;
  border-radius: 6px;
}
</style>
